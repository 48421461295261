import { MenuOutlined } from "@ant-design/icons";
import { Col, Drawer, Row } from "antd";
import router, { useRouter } from "next/router";
import { useState } from "react";
import { cls } from "../../../utils/frontend/utils";
import useBreakpoint from "../../../hooks/useBreakPoint";
import styles from "./index.module.css";
import Image from "next/image";
const APP = process.env.NEXT_PUBLIC_APP;

export default function AppHeader({ logo, logoHref, showAuthLinks, showOtherCertikAppLinks }) {
  const { asPath } = useRouter();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const screens = useBreakpoint();
  const appLogoConfig = {
    url: "/skyharbor.svg",
    width: 200,
    height: 50,
    alt: "skyharbor",
  };

  return (
    <div className={cls(styles, ["AppHeader"], ["AppHeader"])}>
      <div className={cls(styles, ["container"])}>
        <Row align="center" justify="space-between" style={{ height: "100%" }}>
          <Col>
            <a onClick={() => router.push(logoHref || "/")}>
              {logo || (
                <Image
                  src={appLogoConfig.url}
                  alt={appLogoConfig.alt}
                  width={appLogoConfig.width}
                  height={appLogoConfig.height}
                />
              )}
            </a>
          </Col>
          {screens.lg ? (
            <Col>
              <Row gutter={33} style={{ height: "100%" }}>
                {showOtherCertikAppLinks && (
                  <>
                    <Col>
                      <a href="https://shield.shentu.technology" target="_blank" rel="noreferrer">
                        ShentuShield
                      </a>
                    </Col>
                    <Col>
                      <a href="https://explorer.shentu.technology" target="_blank" rel="noreferrer">
                        Explorer
                      </a>
                    </Col>
                    <Col>
                      <a href="https://wallet.shentu.technology" target="_blank" rel="noreferrer">
                        DeepWallet
                      </a>
                    </Col>
                    <Col>
                      <a href="https://www.certik.org/technology" target="_blank" rel="noreferrer">
                        Technology
                      </a>
                    </Col>
                    <Col>
                      <a href="https://www.certik.org/blog" target="_blank" rel="noreferrer">
                        Blog
                      </a>
                    </Col>
                  </>
                )}
                {showOtherCertikAppLinks && showAuthLinks && (
                  <Col style={{ fontWeight: 600 }}>|</Col>
                )}
                {showAuthLinks && (
                  <>
                    <Col>
                      <a onClick={() => router.push("/login")} style={{ fontWeight: 600 }}>
                        Login
                      </a>
                    </Col>
                    <Col>
                      <a
                        onClick={() => router.push("/signup")}
                        className={cls(styles, [
                          "signup-link",
                          asPath.includes("/signup") && "signup-link-activated",
                        ])}
                      >
                        Sign Up
                      </a>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          ) : (
            showAuthLinks &&
            showOtherCertikAppLinks && (
              <Col>
                <MenuOutlined
                  className={cls(styles, ["drawer-btn"])}
                  onClick={() => setIsDrawerVisible(true)}
                />
              </Col>
            )
          )}
        </Row>
      </div>
      <Drawer
        placement="right"
        closable={true}
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
      >
        <Row gutter={[{}, 20]}>
          {showOtherCertikAppLinks && (
            <>
              <Col span={24}>
                <a href="https://shield.shentu.foundation" target="_blank" rel="noreferrer">
                  ShentuShield
                </a>
              </Col>
              <Col span={24}>
                <a href="https://explorer.certik.foundation" target="_blank" rel="noreferrer">
                  Explorer
                </a>
              </Col>
              <Col span={24}>
                <a href="https://wallet.certik.foundation" target="_blank" rel="noreferrer">
                  DeepWallet
                </a>
              </Col>
              <Col span={24}>
                <a href="https://www.certik.org/technology" target="_blank" rel="noreferrer">
                  Technology
                </a>
              </Col>
              <Col span={24}>
                <a href="https://www.certik.org/blog" target="_blank" rel="noreferrer">
                  Blog
                </a>
              </Col>
            </>
          )}
          {showAuthLinks && showOtherCertikAppLinks && (
            <Col>
              <hr style={{ width: 208 }} />
            </Col>
          )}
          {showAuthLinks && (
            <>
              <Col span={12}>
                <a onClick={() => router.push("/login")} style={{ fontWeight: 600 }}>
                  Login
                </a>
              </Col>
              <Col span={12}>
                <a onClick={() => router.push("/signup")} className={cls(styles, ["signup-link"])}>
                  Sign Up
                </a>
              </Col>
            </>
          )}
        </Row>
      </Drawer>
    </div>
  );
}
